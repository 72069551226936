import React from 'react';
import {Link} from 'react-router-dom';

import $ from "jquery";

import lineup_genie_image from '../../assets/frontend/images/lineup_genie.png';
import lineup_genie_mobile_image from '../../assets/frontend/images/lineup_genie.png';

function Home(){

	var RegisterOrDashboard = "/register";
	var LoginOrSchedule = "/login";

    if(localStorage.getItem('auth_users_name') && localStorage.getItem('auth_email')
	&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
		var RegisterOrDashboard = "/member/dashboard";
		var LoginOrSchedule = "/member/schedule";
	}

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="panel large ">
				<div className="grid-x pt-20">
					<div className="large-12 medium-12 small-12 cell text-center">
					<img src={lineup_genie_image} />
					</div>
					<div className="large-12 medium-12 small-12 cell pt-20 text-center">
						<div className="font-raleway font-xxx-large font-weight-500 txt-333 uppercase">LINEUP GENIE</div>
						<div className="font-raleway font-medium font-weight-500 txt-333 uppercase pt-10">Optimize Your Lineup</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default Home;