
import Home from './components/frontend/Home';
import Search from './components/frontend/Search';
import About from './components/frontend/About';
import Contact from './components/frontend/Contact';
import Help from './components/frontend/Help';

import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import ActivateAccount from './components/frontend/auth/ActivateAccount';
import ForgotPassword from './components/frontend/auth/ForgotPassword';
import ResetPassword from './components/frontend/auth/ResetPassword';

import MemberDashboard from './components/member/Dashboard';
import MemberProfile from './components/member/Profile';
import MemberFeature1 from './components/member/Feature1';
import MemberFeature2 from './components/member/Feature2';
import MemberSchedule from './components/member/Schedule';
import MemberAccount from './components/member/Account';

const ActivateAccountWithId = ({ id }) => <ActivateAccount id={id} />;
const ResetPasswordWithIdEmail =  ({ id, email }) => <ResetPassword id={id} email={email} />;
//const MemberClassViewerWithRandomId =  ({ user_class_random_id }) => <MemberClassViewer user_class_random_id={user_class_random_id} />;

const routes = [
	{
		path: '/',
		element: <Home />,
		type: 'public',
	}, {
		path: '/home',
		element: <Home />,
		type: 'public',
	}, {
		path: '/search',
		element: <Search />,
		type: 'public',
	}, {
		path: '/about',
		element: <About />,
		type: 'public',
	}, {
		path: '/contact',
		element: <Contact />,
		type: 'public',
	}, {
		path: '/help',
		element: <Help />,
		type: 'public',
	}, {
		path: '/login',
		element: <Login />,
		type: 'public',
	}, {
		path: '/register',
		element: <Register />,
		type: 'public',
	}, {
		path: '/activate_account/:id',
		element: <ActivateAccountWithId />,
		type: 'public',
	}, {
		path: '/forgot_password',
		element: <ForgotPassword />,
		type: 'public',
	}, {
		path: '/reset_password/:id/:email',
		element: <ResetPasswordWithIdEmail />,
		type: 'public',
	}, {
		path: '/member/dashboard',
		element: <MemberDashboard />,
		type: 'private member',
	}, {
		path: '/member/feature_1',
		element: <MemberFeature1 />,
		type: 'private member',
	}, {
		path: '/member/feature_2',
		element: <MemberFeature2 />,
		type: 'private member',
	}, {
		path: '/member/schedule',
		element: <MemberSchedule />,
		type: 'private member',
	}, {
		path: '/member/account',
		element: <MemberAccount />,
		type: 'private member',
	}, {
		path: '/member/profile',
		element: <MemberProfile />,
		type: 'private member',
	},
	// And so on.

	//Tests
];

export default routes;