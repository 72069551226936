import React, { useState } from 'react';
import {Link} from 'react-router-dom';

const steps = [
  { name: 'Step 1', component: Step1 },
  { name: 'Step 2', component: Step2 },
  { name: 'Step 3', component: Step3 },
  { name: 'Review', component: Review },
];
	
function Step1({ formData, onChange }) {
	return (
	<div>
		<label>Input:
		<input
			type="text"
			value={formData.step1Data}
			onChange={(e) => onChange('step1Data', e.target.value)}
		/>
		</label>
	</div>
	);
}

function Step2({ formData, onChange }) {
	return (
	<div>
		<label>Input:
		<input
			type="text"
			value={formData.step2Data}
			onChange={(e) => onChange('step2Data', e.target.value)}
		/>
		</label>
		<label>Quantity:
		<input
			type="number"
			value={formData.step2Quantity}
			onChange={(e) => onChange('step2Quantity', e.target.value)}
		/>
		</label>
	</div>
	);
}

function Step3({ formData, onChange }) {
	return (
	<div>
		<label>Input:
		<input
			type="text"
			value={formData.step3Data}
			onChange={(e) => onChange('step3Data', e.target.value)}
		/>
		</label>
	</div>
	);
}

function Review({ formData }) {
	return (
	<div>
		<p>Step 1 Data: <span className="font-weight-600">{formData.step1Data}</span></p>
		<p>Step 2 Data: <span className="font-weight-600">{formData.step2Data}</span></p>
		<p className="pl-15">Quantity: <span className="font-weight-600">{formData.step2Quantity}</span></p>
		<p>Step 3 Data: <span className="font-weight-600">{formData.step3Data}</span></p>
	</div>
	);
}

function Feature1(){

	const [currentStep, setCurrentStep] = useState(0);
	const [formData, setFormData] = useState({
	  step1Data: '',
	  step2Data: '',
	  step2Quantity: '',
	  step3Data: '',
	});
  
	const handleChange = (field, value) => {
	  setFormData(prevData => ({
		...prevData,
		[field]: value,
	  }));
	};
  
	const handleNext = () => {
	  if (currentStep < steps.length - 1) {
		setCurrentStep(prev => prev + 1);
	  }
	};
  
	const handlePrevious = () => {
	  if (currentStep > 0) {
		setCurrentStep(prev => prev - 1);
	  }
	};
  
	const handleGoToStep = (stepIndex) => {
	  setCurrentStep(stepIndex);
	};
  
	const CurrentComponent = steps[currentStep].component;

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase">Feature 1</div>
					</div>
					<div className="large-12 medium-12 small-12 cell text-left pt-10">
						{steps.map((step, index) => (
						<Link key={index} onClick={() => handleGoToStep(index)} className="pr-10">
						{step.name}
						</Link>
						))}
					</div>
				</div>
			</div>

			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-medium font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
					<div className="large-12 medium-12 small-12 cell pt-10">
						<CurrentComponent
						formData={formData}
						onChange={handleChange}
						/>
						<div className="pt-10 text-center">
						{currentStep > 0 && (
						<Link onClick={handlePrevious} className="plr-10">Back</Link>
						)}
						{currentStep < steps.length - 1 ? (
						<Link onClick={handleNext} className="plr-10">Next</Link>
						) : (
						<Link onClick={() => console.log('Submit', formData)} className="">Calculate</Link>
						)}
						</div>
						<div className="text-center pt-20">
						{steps.map((step, index) => (
						<Link key={index} onClick={() => handleGoToStep(index)} className="plr-10">
						{step.name}
						</Link>
						))}
						</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default Feature1;