import React from 'react';

import safari_popups_image from '../../assets/frontend/images/safari_popups.jpeg';

function Help(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Help</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-left hide-for-small-only">Technical Issues</div>
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-center show-for-small-only">Technical Issues</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						If you run into any technical issues please contact us at <a href="mailto:support@LineupGenie.com" className="" target="_blank">support@LineupGenie.com</a>.
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-left hide-for-small-only">Zoom App - Apple Devices</div>
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-center show-for-small-only">Zoom App - Apple Devices</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Due to increased security and permissions with Mac OS on Apple devices running Safari, you will be prompted to authorize the Zoom Client App to use the microphone, camera, and on Mac OS, screen recording.
						You will also be prompted to allow the Zoom Client App to allow others to remotely control your desktop. You can click OK during the initial request and you will be able to use your microphone and camera in Zoom.
						For more detailed information please visit the <a href="https://support.zoom.us/hc/en-us/articles/360016688031-Using-the-Zoom-Client-and-Zoom-Rooms-with-macOS" className="font-raleway font-medium font-weight-500 txt-222" target="_blank">Zoom App support for Apple users</a>.
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						If you are an <span className="font-weight-600">Apple Safari/macOS</span> user, you must go to your browser or macOS security settings and allow the Zoom App to launch.
						</div>
						<div className="text-center pt-20">
							<img src={safari_popups_image} alt="safari popups" width="600"/>
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						If you are a <span className="font-weight-600">Chrome</span> or <span className="font-weight-600">Firefox</span> user you may need to allow popup windows to launch the Zoom App.
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-left hide-for-small-only">Refer A Friend</div>
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-center show-for-small-only">Refer A Friend</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						You can now refer a friend in your dashboard, and both of you will receive one free class. Use the &nbsp; <i className="fas fa-user-plus "></i> &nbsp; icon on the right side of the dashboard.
						Referral offer expires 14 days after advocate&#39;s share. To receive a free class, your friend must purchase a minimum of one class.
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-left hide-for-small-only">Dashboard Icons</div>
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-center show-for-small-only">Dashboard Icons</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per pt-20"><i className="fas fa-calendar-alt fas-med"></i>&nbsp;&nbsp;&nbsp;Schedule: list, book, cancel, view.</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per pt-20"><i className="fas fa-list-alt fas-med"></i>&nbsp;&nbsp;&nbsp;Account: booked, cancelled, and payments.</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per pt-20"><i className="fas fa-envelope-open-text fas-med bdivnk"></i>&nbsp;&nbsp;&nbsp;Referred Message: instructions to claim a free class.</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per pt-20"><i className="fas fa-user-plus fas-med"></i>&nbsp;&nbsp;&nbsp;Refer A Friend: add the email of a friend to join and receive 1 free class.</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per pt-20"><i className="fas fa-shopping-cart fas-med"></i>&nbsp;&nbsp;&nbsp;Purchase: select a package and start the payment process.</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default Help;