import React from 'react';
import {Link} from 'react-router-dom';


function About(){
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">About</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-20">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">
					<div className="large-4 medium-5 small-12 cell hide-for-small-only">
						<div className="text-left pr-20 hide-for-small-only">
							{/*
							<img src={ally_4_image} alt="ally 4" width="100%"/>
							*/}
						</div>
					</div>
					<div className="large-8 medium-7 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-left-center-on-small">Lineup Genie</div>
						<div className="text-center show-for-small-only pt-20">
						{/*
							<img src={ally_4_image} alt="ally 4" width="100%"/>
							*/}
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Something something something. Something something something. Something something something. Something something something. Something something something. 
						Something something something. Something something something. Something something something. Something something something. Something something something. 
						Something something something. Something something something. Something something something. Something something something. Something something something. 
						Something something something. Something something something. Something something something. Something something something. Something something something. 
						Something something something. Something something something. Something something something. Something something something. Something something something. 
						</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default About;